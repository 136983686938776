/** @jsx jsx */
import HomePageLayout from '../../../components/HomePageLayout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import Text_calltoaction from '../../../components/raw_templates/Text_calltoaction';
import Link from '@fify.at/gatsby-theme-base/src/components/Link';
import Subheading from '@fify.at/gatsby-theme-base/src/components/Subheading/Subheading';
import Divider from '@fify.at/gatsby-theme-base/src/components/Divider/Divider';

export default function Wertindikation({ location, data }) {
  return (
    <HomePageLayout
      location={location}
      lang="de"
      title="So viel ist mein Haus wirklich wert!"
      description="Die Wertindikation und ein Bewertungsgutachten sind wichtige Parameter bei der professionellen Bewertung Ihrer Immobilie. Die richtige Einschätzung Ihres Immobilienwerts gibt Ihnen Sicherheit beim Verkauf Ihres Hauses."
      image={data.hero.childImageSharp.fluid}
      keywords={['Wertindikation', 'Bewertungsgutachten']}
      header={{ attachment: 'default', appearance: 'default', fluid: true }}
    >
      <HeroSectionContainer
        fluid={false}
        orientation="southEast"
        variant="alpha"
        title="Wertindikation & Bewertungsgutachten"
        subtitle="So viel ist Ihr Haus wert!"
        image={data.hero}
      />
      <Section container="small">
        <div sx={{ marginBottom: 7 }}>
          <Heading>Mehr Sicherheit beim Hausverkauf</Heading>
          <Subheading>
            durch die richtige Einschätzung Ihres Immobilienwerts
          </Subheading>
        </div>

        <p>
          Die richtige Einschätzung Ihres Immobilienwerts gibt Ihnen Sicherheit
          beim{' '}
          <Link to="/haus-verkaufen/ihr-haus-ihre-regeln-schneller-hausankauf/">
            Verkauf Ihres Hauses
          </Link>
          .
          <br />
          Unsere regionalen Immobilienexperten sowie ein professioneller
          Immobilienbewerter helfen Ihnen dabei!
        </p>
        <h3>Online Wertindikation</h3>
        <p>
          Bei unserer Online Wertindikation handelt es sich um eine erste
          qualifizierte und unabhängige Einschätzung des Wertrahmens für Ihre
          Immobilie auf Basis von wenigen zur Verfügung stehenden Informationen.
          Diese eignet sich vor allem für eine erste Verkaufsüberlegung sowie
          für die erste eigene interne Einschätzung der Lage.
        </p>
        <p>
          Nach Beantwortung nur wenigen Fragen zur Lage, Größe und Ausstattung
          Ihrer Immobilie erhalten Sie eine aussagekräftige Werteindikation.
        </p>
        <p>
          WirkaufendeinHaus.at stellt Ihnen diese{' '}
          <Link to="/online-immobilienbewertung/">Online Wertindikation</Link>{' '}
          selbstverständlich kostenlos zur Verfügung.
        </p>
        <h3>Bewertung durch einen Profi vor Ort</h3>
        <p>
          Haben Sie einen Termin mit einem unserer Experten vor Ort angefordert,
          erstellt dieser ein schriftliches Bewertungsgutachten maßgeschneidert
          auf Ihre Immobilie.
          <br />
          Der Experte kann natürlich Faktoren wie Lage, Zustand und mögliches
          Marktinteresse genauer einschätzen und lässt in seine Bewertung viele
          Erfahrungswerte einfließen. Dadurch bekommen Sie einen genauen
          Verkehrswert Ihrer Immobilie (der natürlich von der Online
          Wertindikation abweichen kann). Die Bewertung ist für Sie kostenlos,
          das Gutachten in Schriftform bekommen Sie bei Annahme des Angebotes
          oder gegen separate Bezahlung ausgehändigt.
        </p>
        <h3>Was ist ein Bewertungsgutachten?</h3>
        <p>
          Ein Bewertungsgutachten bzw. Verkehrswertgutachten umfasst alle
          relevanten Details und baurechtlichen Gegebenheiten sowie eine
          komplette Wertanalyse des Gebäudes und des Grundstückswertes auf Basis
          von Vergleich mit anderen Immobilienverkäufen in Ihrer Gegend,
          Ermittlung des Sachwertes Ihrer Liegenschaft und der Marktkenntnis und
          – erfahrung der Bewertungsexperten. Natürlich richtet sich unsere
          Bewertung nach dem österreichischen Liegenschaftsbewertungsgesetz
          sowie sonstigen Normen und Literatur.
        </p>
        <h3>Was genau bedeutet der Verkehrswert?</h3>
        <p>
          Der Verkehrswert ist jener Wert, den man mit 80% Wahrscheinlichkeit
          maximal innerhalb von 6 bis 12 Monaten erzielen können wird. Er stellt
          also einen Maximalwert dar.
        </p>
        <p>
          <strong>TIPP:</strong> Bekommen Sie von einem Makler einen Preis
          genannt, lassen Sie sich zum Makler-Auftrag immer das
          Bewertungsgutachten in Schriftform aushändigen, um später eventuell
          einen Beweis in Händen zu halten, sollte sich die Einschätzung als
          fahrlässig oder vorsätzlich falsch herausstellen.
        </p>
        <p>
          <strong>Beispiel 1:</strong> Makler schätzt das Haus viel zu hoch, um
          einen Auftrag zu bekommen. Dadurch entsteht neben einer sehr langen
          Vermarktungsdauer meist ein großer Verlust durch anfallende
          Betriebskosten und einen schlechten Verkaufserlös.
        </p>
        <p>
          <strong>Beispiel 2:</strong> Ihr Haus wird vom Makler zu günstig
          geschätzt, damit möglicherweise dessen Aufwand so gering wie möglich
          bleibt.
        </p>
      </Section>

      <Text_calltoaction
        sectionBackground="muted"
        title="Sie wollen vorab schon wissen, was Ihr Haus wert ist?"
        text="<p>Dann nutzen Sie jetzt unseren Online Immobilienrechner!<br/>
      Nach Beantwortung von ein paar Fragen und Bekanntgabe der relevanten Daten erhalten Sie in wenigen Schritten eine erste Einschätzung, welchen Wert Ihre Immobilie bei einem eventuellen Verkauf in Ihrer Region erzielen kann.<br/>
      Finden Sie schnell und einfach heraus, wie der Wert Ihres Haus am Immobilienmarkt eingeschätzt wird – kostenlos und ohne Registrierung!</p>"
        buttons={[
          {
            text:
              'Online Immobilienbewertung jetzt starten <i class="fal fa-arrow-right"></i>',
            to: '/online-immobilienbewertung/',
          },
        ]}
      />

      <Text_calltoaction
        sectionBackground="white"
        title="Oder wollen Sie es ganz genau wissen?"
        text="<p>In einem persönlichen Gespräch lässt sich vieles ganz einfach klären. Unsere regionalen Immobilienexperten sind für Sie da und freuen sich darauf, Sie beim Hausverkauf unterstützen zu dürfen – natürlich kostenfrei und unverbindlich!</p>"
        buttons={[
          {
            text: 'Termin vereinbaren <i class="fal fa-arrow-right"></i>',
            to: '/terminbuchung/',
          },
        ]}
      />
    </HomePageLayout>
  );
}

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-wertindikation" }) {
      childImageSharp {
        fluid(maxWidth: 2340, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
